@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@200;600&display=swap');

@layer base {
  @font-face {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: 200;
  }
  @font-face {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: 600;
  }
}

html, body, #root, .h100 {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

p {
  @apply text-base;
}

.bold {font-weight: 600;}

.thin {font-weight: 200;}

.horizontal-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  white-space: nowrap;
}
.horizontal-scroll::-webkit-scrollbar {
display: none;
width: 0;
}


.hero {
  height: 100vh;
  /*background: url(./Images/Hero.jpg) no-repeat center center; */
  background: url(./Images/Hero_1.jpg) no-repeat center center;
}

.bg-ev-1 { background: url(./Images/ev-1.jpg) no-repeat center center; }
.bg-dish-1 { background: url(./Images/dish-1.jpg) no-repeat center center; }
.bg-tado-1 { background: url(./Images/tado-1.jpg) no-repeat center center; }

.underline-offset {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.benefits-card {
  min-width: 260px;
  @apply min-h-400 flex items-center justify-center bg-white rounded-lg bg-cover mx-4 md:mx-0;
}

.padded-multiline { 
  line-height: 1.3; 
  padding: 2px 0; 
  border-left: 20px solid rgb(255, 255, 255);
  margin: 20px auto;
}
.padded-multiline h2 { 
  background-color: rgb(255, 255, 255);
  padding: 4px 0;
  display: inline;
  margin: 0; 
}
.padded-multiline h2 span { 
  position: relative;
  left: -10px; 
}

.white-no-wrap {
  white-space: nowrap;
}


/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item + .accordion__item {
}

.accordion__button {
  cursor: pointer;
  border: none;
  @apply w-full text-base md:text-l bg-lightgrey text-left p-4 text-black rounded md:rounded-xl my-2;
}

.accordion__button:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  margin-bottom: 2px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.bm-icon {
  width: 30px!important;
}

.bm-burger-button {
  background: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px!important;
}

.bm-menu-wrap {
  @apply bg-logo p-10 text-white text-base;
}

.bm-menu {
  overflow: hidden!important;

}

.bm-item:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.bm-item {
  @apply text-white text-base my-4;
}

.greyscale {
  filter: grayscale(100%);
}

#sections {
  font-weight: bold;
}



table {
  border-collapse: collapse;
}

table,
td,
th {
  border: 1px solid black;
}

th {
  background: #BFBFBF;
}

th,
td {
  text-align: left;
  vertical-align: top;
  padding: 6px;
}